/**

CSS CODE JUST FOR DEVELOPMENT PURPOSES, DELETE IT, BEFORE INTEGRATING WITH WP!!!!

 */

.investment__range {
  &-text {
  display: flex;
  justify-content: space-between;

    &--title {
      font-size: 18px;
      letter-spacing: 0;
      font-family: "proxima-nova", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      color:#fff;
    }
    &--value {
      font-family: Proxima Nova, serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0px;
    }
    &--value-suffix {
      font-size: 18px;
      letter-spacing: 0;
      font-family: "proxima-nova", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
    }
  }

  &-slider {
    margin: 20px 0;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    background: #ffffff;
    outline: none;

    /* CHROME */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      background: #ecaa14;
      cursor: pointer;
      border-radius: 50%;
    }
    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      color: red;
    }

    &::-moz-range-thumb {
      width: 18px;
      height: 18px;
      background: #ecaa14;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}

.graph-content {

  &__label {
    fill: #4c79a5;
    font-family: "proxima-nova", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;

    &-current {
      fill: #6BABDF;
      font-family: Proxima Nova;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  &__mainLine {
    stroke: #ecaa14;
    stroke-width: 2;
    stroke-dasharray: 3;
  }

  &__tooltip-yield{
    font-family: "proxima-nova", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
  }

  &__tooltip-price{
    font-family: Lora;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: -1px;
    text-align: left;
  }
  &__tooltip-suffix{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -1px;
    text-align: left;
  }

}

